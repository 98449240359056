export const APP_ROUTES = {
  BASE: "",
  VERIFY_LOGIN: "verify-login",
  OVERVIEW: "overview",
  LISTINGS: "listings",
  CALENDAR: "calendar",
  COMMUNITY: "community",
  RESERVATIONS: "reservations",
  DISCOVER: "discover",
  INBOX: "inbox",
  TASKS: "tasks",
  FINANCIALS: "financials",
  ACCOUNTING: "accounting",
  REPORTING: "reporting",
  CONTACTS: "contacts",
  SETTINGS: "settings",
  SETTINGS_GLOBAL: "settings-global",
  LOGIN: "account/login",
  REGISTER: "account/register",
  SEARCH: "search",
  USERS: "users",
  ERROR: "error",
  ASSOCIATIONS: "associations",
  DASHBOARD_HOME: "home",
  MARKETPLACE: "marketplace",
  MESSAGES: "messages",
  MAINTENANCE: "maintenance",
  INSURANCE: "insurance",
  ANNOUNCEMENTS: "announcements",
  VIOLATIONS: "violations",
  PAYMENTS: "payments",
  MODIFICATIONS: "modifications",
  DOCUMENT_CENTER: "documents",
  ACCOUNT_PROFILE: "profile",
  LEASE_MANAGEMENT: "leasing",
  BUDGETS: "reporting/budgets",
  RENTAL_APPLICATION: "resident-portal/rental-application",
  BOARD_PORTAL: "board-portal",
  JOBS: "jobs",
  PROPERTIES: "properties",
  TIMESHEET: "timesheet",
  WORK_ORDERS: "work-orders",
  COMMUNICATION: "communication",
  OAUTH_CALLBACK: "oauth",
  NOTIFICATIONS: "notifications",
} as const;
